.exampleImageModal .modal-content{
    background: transparent !important;
    border: none !important;

    .modal-header{
        border-bottom: none;
    }

    .btn-close{    box-sizing: content-box;
        width: 2em;
        height: 2em;
        padding: 0.25em 0.25em;
        background: #f1b44c url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
        border: 1px solid #f1b44c;
        border-radius: 50%;
        opacity: 1;}
}


// .modal-open{
//     div{
//         z-index: 800 !important;
//     }
// }