.img-wrap {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
  
    >span{
      border-radius: 100%;
    }

    img {
        width: 100%;
        border-radius: 50%;
        height: 100px;
    }
  
    .icon-wrap {
      position: absolute;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #000;
      border-radius: 50%;
      background-color: #CC962B;
      right: -0.6rem;
      top: -0.6rem;
  
      .icon {
        width: 14px;
        height: 14px;
      }
    }
    .file-picker {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 100%;
      position: absolute;
      opacity: 0;
      right: -0.6rem;
      top: -0.6rem;
      z-index: 1;
      cursor: pointer;
    }
  }